import styled from 'styled-components'

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.small};
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colors.white};
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
`

export default SectionTitle
