import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import SEO from './SEO'
import theme from '../../config/theme'

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    height: 100%;
    color: ${props => props.theme.colors.grey.default};
    background: #333;
    background-image: url(../images/cubes.png);
    background-image: url(../images/cubes.png), linear-gradient(to bottom right, #434343 0%, black 100%);
    background-repeat: repeat;
    padding: 0;
    margin: 0;
    overflow-y: auto;
  }
  ::selection {
    color: ${props => props.theme.colors.bg};
    background: ${props => props.theme.colors.selection};
  }
  html {
    font-family: ${props => props.theme.fontFamily.mono};
    font-size: ${props => props.theme.baseFontSize};
    h1 {
      font-family: ${props => props.theme.fontFamily.mono};
      font-size: 3.052rem;
      font-weight: 400;
    }
    h2 {
      font-size: 2.441rem;
      font-family: ${props => props.theme.fontFamily.mono};
      font-weight: 400;
    }
    h3 {
      font-size: 1.953rem;
      font-family: ${props => props.theme.fontFamily.mono};
      font-weight: 400;
    }
    h4 {
      font-size: 1.563rem;
      font-family: ${props => props.theme.fontFamily.mono};
      font-weight: 400;
    }
    h5 {
      font-size: 1.25rem;
      font-family: ${props => props.theme.fontFamily.mono};
      font-weight: 400;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 16px;
      h1 {
        font-size: 2.488rem;
      }
      h2 {
        font-size: 2.074rem;
      }
      h3 {
        font-size: 1.728rem;
      }
      h4 {
        font-size: 1.444rem;
      }
      h5 {
        font-size: 1.2rem;
      }
    }
  }
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: all ${props => props.theme.transitions.normal};
  }
  a:hover {
    color: ${props => props.theme.colors.primaryLight};
  }
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      outline: 0;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.grey.dark};
    font-family: ${props => props.theme.fontFamily.serif};
  }
  p {
    margin-bottom: 1.5rem;
  }
  blockquote {
    font-style: italic;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background: ${props => props.theme.colors.primary};
      height: 100%;
      width: 6px;
      margin-left: -1.6rem;
    }
  }
  label {
    margin-bottom: .5rem;
    color: ${props => props.theme.colors.grey.dark};
  }
  input, textarea, button {
    font-size: 1rem;
  }
  textarea {
    font-family: ${props => props.theme.fontFamily.sansSerif};
  }
  input, textarea {
    border-radius: .5rem;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    padding: .4rem 1rem;
    &:focus {
      outline: none;
    }
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
  figure {
    margin: 0 0 1rem 0;
  }
  img {
    vertical-align: middle;
  }
  [role='button'] {
    cursor: pointer;
  }
  a,
  area,
  button,
  [role='button'],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
    background-color: ${props => props.theme.colors.bg};
  }
  caption {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: ${props => props.theme.colors.color};
    text-align: center;
    caption-side: bottom;
  }
  th {
    text-align: left;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
  }
  input[type='search'] {
    -webkit-appearance: none;
  }
  output {
    display: inline-block;
  }
  svg:not(:root) {
    overflow: hidden;
    vertical-align: middle;
  }
  [hidden] {
    display: none !important;
  }
`

const Footer = styled.footer`
  height: 9vh;
  max-width: 1000px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 auto;

  .copyright {
    display: flex;
    padding: 0 1rem 1.5rem 1rem;
    color: white;
    font-size: 0.75rem;

    img {
      margin-bottom: 0.15rem;
    }
  }
  .nav {
    display: flex;
    justify-content: flex-end;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style-type: none;
      padding-left: 0;
      padding-bottom: 0.75rem;

      li {
        padding: 0 1rem;
        font-size: 0.75rem;
        text-transform: uppercase;

        a {
          color: ${props => props.theme.colors.white}
        }
      }
    }
  }
`

const Layout = ({ children, customSEO }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      {children}
      <Footer>
        <span className="copyright">
          &copy; 2019 by Tyler Andor. &nbsp;
          <a rel="license" to="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/80x15.png" /></a>
        </span>
        <nav className="nav">
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </nav>
      </Footer>
    </React.Fragment>
  </ThemeProvider>
)

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool,
}

Layout.defaultProps = {
  customSEO: false,
}
