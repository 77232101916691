import styled from 'styled-components'

const Subline = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: ${props => props.theme.fontSize.small};
  color: ${props => props.theme.colors.grey.default};
  font-family: sans-serif;
  ${props => props.sectionTitle && 'margin-top: -1rem'};
  ${props => props.sectionTitle && 'margin-bottom: 2rem'};
  ${props => props.sectionTitle && 'text-align: center'};
`

export default Subline
